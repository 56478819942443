html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.f-row {
  display: flex;
  flex-direction: row;
}

.f-col {
  display: flex;
  flex-direction: column;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.f-spb {
  display: flex;
  justify-content: space-between;
}

.line {
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
}

.out-text {
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.text-link {
  color: var(--color-main-blue);
  text-decoration: underline;
  cursor: pointer;
}

:root {
  --color-main-blue: #4b84da;
  --color-main-blue-hover: #004e9d;

  --color-main-red: #e34e67;
  --color-main-red-hover: #c12d4d;
  --color-main-light-red: #fdc5be;

  --color-sidebar: #878991;
  --color-sidebar-hover: #4b84da;

  --color-black: #282b35;
  --color-gray: #5f657a;
  --color-gray-hover: #474c5c;
  --color-lightgray: #e6e6e6;
  --color-white: #ffffff;

  --color-yellow: #ffc107;
  --color-green: #38a754;
  --color-pink: #e34e80;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 7px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

.a-none {
  text-decoration: none;
}

th {
  width: 150px;
  font-weight: bold;
}

.th-100 {
  max-width: 100px;
}
.th-200 {
  max-width: 200px;
}
.th-300 {
  max-width: 300px;
}
.th-400 {
  max-width: 400px;
}

a {
  text-decoration: none !important;
}

/* 리스트 테이블 */
.table-custom thead {
  background-color: #f0f0f0;
  font-size: 20px;
}

.table-custom tbody {
  font-size: 16px;
}

/* 모달 테이블 */
.table-custom thead {
  background-color: #f0f0f0;
  font-size: 16px;
  font-weight: bold;
}

.table-custom tbody {
  background-color: #ffffff;
  font-size: 14px;
}

.table-custom th,
.table-custom td {
  padding: 12px; /* 모달 간격 설정 */
}

.font-bold {
  font-weight: bold;
  font-size: 1rem; /* 기준 폰트 크기 설정 */
}

.table-custom th {
  font-weight: bold;
  font-size: 1.1rem; /* 폰트 크기 설정 */
}

.table-custom td {
  font-size: 1rem; /* 폰트 크기 설정 */
}

.table-light {
  background-color: #f0f0f0;
}

.form-check-label {
  /* font-size: 1.1rem; */
}

.form-label {
  font-size: 1.1rem;
}

.table-custom th,
.table-custom td {
  text-align: center;
  vertical-align: middle; /* 세로 중앙 정렬 */
}

.custom-tab .nav-link {
  color: black !important; /* 기본 글자 색상 */
  font-weight: normal;
}

.custom-tab .nav-link.active {
  font-weight: bold; /* 선택된 탭의 글자 볼드 처리 */
}

.custom-tab .nav-link {
  color: black !important; /* 기본 글자 색상 */
  font-weight: normal;
}

.custom-tab .nav-link.active {
  font-weight: bold; /* 선택된 탭의 글자 볼드 처리 */
}

.card-custom {
  border: 1px solid #ced4da;
  margin-bottom: 1rem;
}

.card-custom-header {
  background-color: #f8f9fa;
  font-size: 1.25rem;
  font-weight: 600;
}

.card-custom-body {
  font-size: 1rem;
}

.card-custom .input-group {
  margin-bottom: 1rem;
}

.card-custom .btn {
  font-size: 0.875rem;
}

.input-group-text-custom {
  font-size: 1.1rem !important; /* 폰트 크기 설정 */
  font-weight: bold !important; /* 폰트 볼드 처리 */
}

.font-bold-pay {
  /* 결제페이지 폰트*/
  font-weight: bold !important;
  font-size: 1.1em !important;
}

.center-align {
  /* 결제페이지*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.wide-th-pay {
  width: 200px; /* 결제페이지 넓이*/
}

.font-large-pay {
  font-size: 1.2em !important; /* 결제페이지 폰트*/
}

.label-large-pay {
  font-size: 1.1em; /* 결제페이지 라벨 폰트 사이즈 */
}

.label-large-signup {
  /* 화원가입 폰트*/
  font-weight: bold !important;
  font-size: 1.1em !important;
}

.ontactweb-height {
  height: 50px;
}

.font-large-Shop {
  font-size: 1.1em !important;
}

.font-bold-shop-modify {
  /* 구매대행 수정 폰트 */
  font-weight: bold !important;
  font-size: 1.1em !important;
  letter-spacing: 0.05em !important;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .responsive-image {
    max-width: 90%;
    height: 600px;
    object-fit: contain;
  }
}

.font-large-page {
  font-size: 1.25em !important; /* 페이지 폰트*/
}

.font-large-page-title {
  font-size: 1.28em !important; /* 페이지 폰트*/
}

.font-large-order-detail {
  font-size: 1.2em !important;
  margin-bottom: 0.5rem !important;
}

.reduced-size-table {
  width: 100%;
  table-layout: fixed;
}

.reduced-size-header {
  font-size: 0.9em;
  padding: 6px 8px;
}

.reduced-size-table th,
.reduced-size-table td {
  padding: 6px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reduced-size-table th {
  font-weight: bold;
}

.font-large-profile {
  font-size: 1.2em !important;
  margin-bottom: 0.2rem !important;
  text-align: center !important;

  display: flex;
  justify-content: space-between;
}

.font-large-profile-name {
  font-size: 1rem !important;
}

.label-large-profile-th {
  font-weight: bold !important;
  font-size: 1rem !important;
}

@media (max-width: 767px) {
  .font-large-profile {
    font-size: 1.2em !important;
  }

  .font-large-profile-name {
    font-size: 1em !important;
  }

  .label-large-profile-th {
    font-size: 1.1em !important;
  }
}

.current-points {
  text-align: center;
  font-size: 1.5em;
  margin: 20px 0;
}
.reduced-size-header {
  font-size: 1.2em;
  padding: 6px 8px;
}

.label-large-point {
  font-weight: bold !important;
  font-size: 1.3em !important;
}

.label-shop-user {
  font-weight: bold !important;
  font-size: 1.5em !important;
}

.point-history-table {
  max-height: 400px;
  overflow-y: auto;
}

.review-width-important {
  width: auto !important;
}

.check-shop-pay {
  font-size: 1.15rem; /* 폰트 크기 설정 */
}

.check-shop-pay-Bank {
  font-size: 1.15rem; /* 폰트 크기 설정 */
  font-weight: bold !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--color-main-blue) !important;
  --bs-btn-border-color: var(--color-main-blue) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-main-blue-hover) !important;
  --bs-btn-hover-border-color: var(--color-main-blue-hover) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-main-blue-hover) !important;
  --bs-btn-active-border-color: var(--color-main-blue-hover) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--color-main-blue) !important;
  --bs-btn-disabled-border-color: var(--color-main-blue) !important;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--color-main-red) !important;
  --bs-btn-border-color: var(--color-main-red) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-main-red-hover) !important;
  --bs-btn-hover-border-color: var(--color-main-red-hover) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-main-red-hover) !important;
  --bs-btn-active-border-color: var(--color-main-red-hover) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--color-main-red) !important;
  --bs-btn-disabled-border-color: var(--color-main-red) !important;
}

.btn-outline-secondary {
  --bs-btn-color: var(--color-gray) !important;
  --bs-btn-border-color: var(--color-gray) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-gray) !important;
  --bs-btn-hover-border-color: var(--color-gray) !important;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-gray) !important;
  --bs-btn-active-border-color: var(--color-gray) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--color-gray) !important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--color-gray) !important;
  --bs-gradient: none;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--color-main-blue) !important;
  --bs-btn-border-color: var(--color-main-blue) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-main-blue-hover) !important;
  --bs-btn-hover-border-color: var(--color-main-blue-hover) !important;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-main-blue-hover) !important;
  --bs-btn-active-border-color: var(--color-main-blue-hover) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--color-main-blue) !important;
  --bs-btn-disabled-border-color: var(--color-main-blue) !important;
}

.btn-outline-success {
  --bs-btn-color: var(--color-main-blue) !important;
  --bs-btn-border-color: var(--color-main-blue) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--color-main-blue) !important;
  --bs-btn-hover-border-color: var(--color-main-blue) !important;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--color-main-blue) !important;
  --bs-btn-active-border-color: var(--color-main-blue) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--color-main-blue) !important;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--color-main-blue) !important;
  --bs-gradient: none;
}

.nav-link {
  color: var(--color-main-blue) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--color-black) !important;
}

.form-check-input:checked {
  background-color: var(--color-main-blue) !important;
  border-color: var(--color-main-blue) !important;
}

.page-link {
  background-color: var(--color-main-blue) !important;
  border-color: var(--color-main-blue) !important;
}

.main-container-boxing {
  display: flex;
  height: 100%;
}

.left-panel-boxing,
.middle-panel-boxing,
.right-panel-boxing {
  padding: 10px;
}

.left-panel-boxing {
  width: 40%;
  overflow-y: auto;
}

.middle-panel-boxing {
  width: 40%;
  overflow-y: auto;
}

.right-panel-boxing {
  width: 30%;
  overflow-y: auto;
}

.table-responsive-boxing {
  max-height: 1000px;
  overflow-y: auto;
}

.coupon-redeem-form {
  margin-bottom: 20px;
}

.coupon-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  overflow: hidden;
  max-width: 400px;
}

.coupon-code-input {
  flex: 1;
  padding: 3px 8px; /* 패딩을 줄여서 높이 조정 */
  font-size: 0.9rem; /* 폰트 크기를 약간 줄임 */
  border: none;
  outline: none;
}

.redeem-button {
  padding: 6px 16px; /* 패딩을 줄여서 버튼 크기 조정 */
  font-size: 0.9rem; /* 버튼 폰트 크기 조정 */
  background-color: var(--color-main-blue);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.redeem-button:hover {
  background-color: var(--color-main-blue-hover);
}

.coupon-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.coupon-item {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
}
