@media screen and (max-width: 1320px) {
  #landing-page {
    .pricing_page_title {
      min-width: auto;
      width: calc(100% - 30px);
    }
  }
}

@media screen and (max-width: 1080px) {
  #landing-page {
    .show_1080 {
      display: block;
    }
    .hide_1080 {
      /* display: none; */
    }

    /**** 메인 페이지 ****/
    .main_banner .text_box {
      position: relative;
      top: auto;
      transform: none;
      left: auto;
      text-align: center;
      top: 15%;
    }
    .main_banner .text_box h2 {
      font-size: 40px;
      line-height: 50px;
    }
    .main_banner .img {
      position: relative;
      top: auto;
      transform: translateX(-50%);
      left: 50%;
      min-width: 300px;
    }
    @keyframes ani {
      0% {
        top: 15%;
      }
      100% {
        top: 18%;
      }
    }
    .part_title_wrap {
      margin-bottom: 60px;
    }
    .part_title_wrap .part_title {
      font-size: 35px;
      line-height: 45px;
    }
    .service_part ul li {
      padding: 50px 30px;
      border-radius: 25px;
    }
    .service_part ul li img {
      width: 150px;
    }
    .service_part ul li .text_box {
      width: calc(100% - 150px);
      padding-left: 30px;
      padding-top: 15px;
    }
    .service_part ul li .text_box h4 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .service_part ul li .text_box p {
      font-size: 16px;
      line-height: 26px;
    }
    .count_part ul li {
      width: 28%;
      margin: 0 1.5%;
      border-radius: 25px;
    }
    .count_part ul li .count {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    .count_part ul li p {
      font-size: 18px;
      line-height: 18px;
    }
    .features_part .symbol {
      height: 80px;
      margin-bottom: 30px;
    }
    .features_part ul li .text_box {
      padding-left: 30px;
    }
    .features_part ul li .text_box h4 {
      font-size: 20px;
      line-height: 28px;
    }
    .features_part ul li .text_box p {
      font-size: 16px;
      line-height: 26px;
    }
    .membership_part .grade .list {
      border-radius: 25px;
      padding: 30px;
    }
    .membership_part .grade .list h4 {
      font-size: 30px;
      line-height: 30px;
    }
    .membership_part .grade .list h5 {
      font-size: 16px;
      line-height: 26px;
    }
    .membership_part .grade .list ul {
      margin-top: 20px;
    }
    .membership_part .grade .list ul li:first-child {
      margin-bottom: 10px;
    }
    .membership_part .grade .list ul li {
      font-size: 14px;
      line-height: 20px;
    }
    .membership_part .grade .list a {
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      margin-top: 30px;
    }
    .membership_part .grade .list.blue img {
      width: 120px;
      top: -5px;
      right: -5px;
    }
    .review_part .list a {
      padding: 30px 0;
    }
    .review_part .list h4 {
      font-size: 20px;
      line-height: 20px;
    }
    .review_part .list .star li {
      width: 14px;
      height: 14px;
    }
    .review_part .list .thumb {
      margin-top: 20px;
    }
    .review_part .list .content {
      margin-top: 20px;
      font-size: 14px;
      padding: 0 15px;
    }
    .shop_part .nice-select {
      display: block;
      margin-bottom: 30px;
      float: none;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      height: 50px;
      line-height: 50px;
    }
    .shop_part .tab_content ul li {
      margin: 10px 5px;
      padding: 15px 20px;
      border-radius: 15px;
    }
    .shop_part .tab_content ul li img {
      height: 25px;
    }
    .blog_part ul li a .content {
      padding: 30px 15px;
    }
    .blog_part ul li a .content h4 {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 5px;
    }
    .blog_part ul li a .content p {
      font-size: 14px;
      line-height: 20px;
    }
    .join_part {
      background-size: contain;
    }
    .join_part .content h3 {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 20px;
    }
    .join_part .content p {
      font-size: 20px;
      line-height: 30px;
    }
    .join_part .content .join_btn {
      margin-top: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      width: 300px;
    }

    /**** service 페이지 ****/
    .link_btn {
      margin-top: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      width: 250px;
    }

    .video_part .text_box h2 {
      font-size: 24px;
      line-height: 34px;
    }
    .video_part .text_box p {
      font-size: 16px;
      line-height: 26px;
    }

    .service_part_1 .text_box,
    .service_part_3 .text_box {
      padding-right: 0;
      width: 100%;
      float: none;
      text-align: center;
    }
    .service_part_1 .video_wrap,
    .service_part_3 .video_wrap {
      right: auto;
      position: relative;
      width: 100%;
      margin-top: 50px;
    }
    .service_part_2 .text_box {
      padding-left: 0;
      width: 100%;
      float: none;
      text-align: center;
    }
    .service_part_2 .video_wrap {
      position: relative;
      left: auto;
      width: 100%;
      margin-top: 50px;
    }

    .service_part_1 ul {
      padding: 30px;
      border-radius: 25px;
    }
    .service_part_1 ul li {
      padding: 30px;
      text-align: center;
    }
    .service_part_1 ul li img {
      float: none;
      width: 150px;
    }
    .service_part_1 ul li .text_box {
      width: 100%;
      padding-left: 30px;
      padding-top: 15px;
      padding-left: 0;
    }
    .service_part_1 ul li .text_box h4 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .service_part_1 ul li .text_box p {
      font-size: 16px;
      line-height: 26px;
    }

    .service_part_2 ul li {
      padding: 30px;
      min-height: 300px;
    }
    .service_part_2 ul li h3 {
      font-size: 20px;
      line-height: 30px;
    }
    .service_part_2 ul li span {
      margin: 20px auto;
      width: 50px;
    }
    .service_part_2 ul li p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 5px;
    }

    .service_part_3 ul li .text_box {
      padding-left: 30px;
    }
    .service_part_3 ul li .text_box h4 {
      font-size: 20px;
      line-height: 28px;
    }
    .service_part_3 ul li .text_box p {
      font-size: 16px;
      line-height: 26px;
    }

    /**** pricing 페이지 ****/
    .pricing_page_title {
      height: 350px;
      border-radius: 40px;
    }
    .pricing_page_title img {
      width: 40%;
      right: 5%;
    }
    .pricing_page_title h2 {
      font-size: 35px;
      line-height: 35px;
      left: 5%;
    }
    .price_info_wrap .nice-select {
      display: block;
      margin-bottom: 20px;
      font-size: 16px;
      height: 50px;
      line-height: 50px;
      font-weight: 600;
    }
    .price_info_wrap .tab_container {
      width: 100%;
      margin-left: 0;
      padding: 50px 30px;
    }
    .price_info_wrap .tab_content {
      min-height: auto;
    }
    .price_info_wrap .tab_content .title {
      margin-bottom: 30px;
    }
    .price_info_wrap .tab_content .title h3 {
      font-size: 20px;
      line-height: 20px;
      padding: 15px 0;
    }
    .price_info_wrap .tab_content .table_title {
      font-size: 16px;
      line-height: 16px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 720px) {
  #landing-page {
    .show_720 {
      display: block !important;
    }
    .hide_720 {
      display: none !important;
    }

    /**** 공통 ****/
    .pad_t_100 {
      padding-top: 10% !important;
    }
    .pad_b_100 {
      padding-bottom: 10% !important;
    }
    .pad_t_150 {
      padding-top: 15% !important;
    }
    .pad_b_150 {
      padding-bottom: 15% !important;
    }
    .mar_b_80 {
      margin-bottom: 8% !important;
    }
    .mar_b_100 {
      margin-bottom: 10% !important;
    }
    .mar_b_150 {
      margin-bottom: 15% !important;
    }
    .mar_t_80 {
      margin-top: 8% !important;
    }
    .mar_t_100 {
      margin-top: 10% !important;
    }
    .mar_t_150 {
      margin-top: 15% !important;
    }

    /**** 메인 페이지 ****/
    .main_banner {
      height: 600px !important;
      border-radius: 35px 35px 0 0 !important;
    }
    .main_banner .text_box h2 {
      font-size: 34px !important;
      line-height: 44px !important;
    }
    .main_banner .text_box p {
      font-size: 18px !important;
      margin-top: 15px !important;
    }
    .part_title_wrap {
      margin-bottom: 30px !important;
    }
    .part_title_wrap .part_title {
      font-size: 24px !important;
      line-height: 34px !important;
    }
    .part_title_wrap p {
      font-size: 16px !important;
      line-height: 26px !important;
      margin-top: 20px !important;
    }
    .service_part ul li {
      text-align: center !important;
      padding: 30px 15px !important;
      margin-bottom: 20px !important;
    }
    .service_part ul li img {
      float: none !important;
    }
    .service_part ul li .text_box {
      float: none !important;
      width: 100% !important;
      padding: 0 !important;
      margin-top: 15px !important;
    }
    .service_part ul li .text_box p {
      font-size: 14px !important;
      line-height: 24px !important;
    }
    .count_part ul li {
      width: 100% !important;
      margin: 0 0 20px !important;
      padding: 30px 0 !important;
    }
    .count_part ul li .count {
      font-size: 35px !important;
      line-height: 35px !important;
      margin-bottom: 15px !important;
    }
    .count_part ul li p {
      font-size: 16px !important;
      line-height: 16px !important;
    }
    .features_part .symbol {
      height: 60px !important;
      margin-bottom: 20px !important;
    }
    .features_part ul li {
      text-align: center !important;
      padding: 30px 0 !important;
    }
    .features_part ul li .img {
      float: none !important;
      margin-bottom: 20px !important;
      width: 100px !important;
    }
    .features_part ul li .text_box {
      padding-left: 0 !important;
      width: 100% !important;
    }
    .features_part ul li .text_box h4 {
      font-size: 18px !important;
      line-height: 28px !important;
    }
    .features_part ul li .text_box p {
      font-size: 14px !important;
      line-height: 24px !important;
    }
    .features_part ul li .text_box .card_list img {
      height: 20px !important;
      margin-right: 10px !important;
    }
    .membership_part .grade .list {
      width: 100% !important;
      margin: 0 0 20px !important;
    }
    .review_part .swiper-container {
      padding: 20px 30px !important;
    }
    .review_part .list {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05) !important;
    }
    .review_part .list h4 {
      font-size: 18px !important;
      line-height: 18px !important;
    }
    .review_part .list .star li {
      width: 12px !important;
      height: 12px !important;
    }
    .review_part .list .thumb {
      margin-top: 15px !important;
    }
    .review_part .list .content {
      margin-top: 15px !important;
    }
    .shop_part .tab_content ul li img {
      height: 20px !important;
    }
    .insta_part ul li {
      width: 50% !important;
      padding: 5px !important;
    }
    .blog_part ul li {
      width: 100% !important;
      margin: 0 0 20px !important;
    }
    .join_part .content h3 {
      font-size: 26px !important;
      line-height: 36px !important;
      margin-bottom: 15px !important;
    }
    .join_part .content p {
      font-size: 18px !important;
      line-height: 28px !important;
    }

    /**** service 페이지 ****/
    .video_part .text_box img {
      height: 200px !important;
      margin-bottom: 30px !important;
    }

    .service_part_1 ul {
      padding: 30px 15px !important;
    }
    .service_part_1 ul li {
      text-align: center !important;
      padding: 30px 15px !important;
      margin-bottom: 20px !important;
    }
    .service_part_1 ul li img {
      float: none !important;
      width: 120px !important;
    }
    .service_part_1 ul li .text_box {
      float: none !important;
      width: 100% !important;
      padding: 0 !important;
      margin-top: 15px !important;
    }
    .service_part_1 ul li .text_box h4 {
      margin-bottom: 10px !important;
      font-size: 18px !important;
    }
    .service_part_1 ul li .text_box p {
      font-size: 14px !important;
      line-height: 24px !important;
    }

    .service_part_2 ul li {
      float: none !important;
      width: 100% !important;
      margin: 0 0 20px !important;
      min-height: auto !important;
    }

    .service_part_3 ul li {
      text-align: center !important;
      padding: 30px 0 !important;
    }
    .service_part_3 ul li .img {
      float: none !important;
      margin-bottom: 20px !important;
      width: 100px !important;
    }
    .service_part_3 ul li .text_box {
      padding-left: 0 !important;
      width: 100% !important;
    }
    .service_part_3 ul li .text_box h4 {
      font-size: 18px !important;
      line-height: 28px !important;
    }
    .service_part_3 ul li .text_box p {
      font-size: 14px !important;
      line-height: 24px !important;
    }

    /**** pricing 페이지 ****/
    .pricing_page_title {
      height: 250px !important;
      border-radius: 25px !important;
    }
    .pricing_page_title img {
      width: 220px !important;
      right: 5% !important;
      bottom: -10% !important;
    }
    .pricing_page_title h2 {
      font-size: 30px !important;
      line-height: 30px !important;
      left: 5% !important;
    }
    .price_info_wrap .tab_content .table_wrap table th {
      font-size: 14px !important;
      line-height: 14px !important;
      height: 50px !important;
    }
    .price_info_wrap .tab_content .table_wrap table td {
      font-size: 14px !important;
      line-height: 20px !important;
      padding: 20px 5px !important;
    }

    /**** 푸터 ****/
    footer {
      padding: 60px 0 !important;
    }
    footer .part {
      width: 100% !important;
      padding: 0 !important;
      margin-bottom: 30px !important;
    }
    footer .part:last-child {
      margin-bottom: 0 !important;
    }
    footer .part h3 {
      font-size: 18px !important;
      line-height: 18px !important;
      margin-bottom: 10px !important;
    }
  }
}

@media screen and (max-width: 480px) {
  #landing-page {
    .show_480 {
      display: block !important;
    }
    .hide_480 {
      display: none !important;
    }
  }
}
