@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@700&display=swap");
/*font-family: 'Noto Sans KR', sans-serif; 볼드 700*/
@font-face {
  font-family: "Noto_demi";
  font-style: normal;
  font-weight: 350;
  src: local("Noto Sans DemiLight"), local("NotoSans-DemiLight"),
    url("/public/landing/fonts/otf/NotoSansKR-DemiLight-Hestia.otf"),
    url("/public/landing/fonts/otf/NotoSansKR-DemiLight-Hestia.otf?#iefix")
      format("embedded-opentype"),
    url("/public/landing/fonts/otf/NotoSansKR-DemiLight-Hestia.otf")
      format("woff"),
    url("/public/landing/fonts/otf/NotoSansKR-DemiLight-Hestia.otf")
      format("opentype");
}

@font-face {
  font-family: "Noto_medium";
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans Medium"), local("NotoSans-Medium"),
    url("/public/landing/fonts/otf/NotoSansKR-Medium-Hestia.otf"),
    url("/public/landing/fonts/otf/NotoSansKR-Medium-Hestia.otf?#iefix")
      format("embedded-opentype"),
    url("/public/landing/fonts/otf/NotoSansKR-Medium-Hestia.otf") format("woff"),
    url("/public/landing/fonts/otf/NotoSansKR-Medium-Hestia.otf")
      format("opentype");
}

@font-face {
  font-family: "Noto_black";
  font-style: normal;
  font-weight: 900;
  src: local("Noto Sans Black"), local("NotoSans-Black"),
    url("/public/landing/fonts/otf/NotoSansKR-Black-Hestia.otf"),
    url("/public/landing/fonts/otf/NotoSansKR-Black-Hestia.otf?#iefix")
      format("embedded-opentype"),
    url("/public/landing/fonts/otf/NotoSansKR-Black-Hestia.otf") format("otf"),
    url("/public/landing/fonts/otf/NotoSansKR-Black-Hestia.otf")
      format("opentype");
}

@font-face {
  font-family: "Pretendard_EB";
  font-weight: 800;
  font-display: swap;
  src: local("Pretendard ExtraBold"),
    url("/public/landing/fonts/Pretendard/woff2/Pretendard-ExtraBold.woff2")
      format("woff2"),
    url("/public/landing/fonts/Pretendard/woff/Pretendard-ExtraBold.woff")
      format("woff");
}

@font-face {
  font-family: "Pretendard_B";
  font-weight: 700;
  font-display: swap;
  src: local("Pretendard Bold"),
    url("/public/landing/fonts/Pretendard/woff2/Pretendard-Bold.woff2")
      format("woff2"),
    url("/public/landing/fonts/Pretendard/woff/Pretendard-Bold.woff")
      format("woff");
}

@font-face {
  font-family: "Pretendard_R";
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"),
    url("/public/landing/fonts/Pretendard/woff2/Pretendard-Regular.woff2")
      format("woff2"),
    url("/public/landing/fonts/Pretendard/woff/Pretendard-Regular.woff")
      format("woff");
}

@font-face {
  font-family: "NanumSquareNeo_R";
  src: url("/public/landing/fonts/eot/NanumSquareNeoTTF-bRg.eot?#iefix")
      format("embedded-opentype"),
    url("/public/landing/fonts/eot/NanumSquareNeoTTF-bRg.eot") format("woff2"),
    url("/public/landing/fonts/eot/NanumSquareNeoTTF-bRg.eot")
      format("font-woff");
  font-weight: 400;
}
@font-face {
  font-family: "NanumSquareNeo_B";
  src: url("/public/landing/fonts/eot/NanumSquareNeoTTF-cBd.eot?#iefix")
      format("embedded-opentype"),
    url("/public/landing/fonts/eot/NanumSquareNeoTTF-cBd.eot") format("woff2"),
    url("/public/landing/fonts/eot/NanumSquareNeoTTF-cBd.eot")
      format("font-woff");
  font-weight: 500;
}
@font-face {
  font-family: "NanumSquareNeo_EB";
  src: url("/public/landing/fonts/eot/NanumSquareNeoTTF-dEb.eot?#iefix")
      format("embedded-opentype"),
    url("/public/landing/fonts/eot/NanumSquareNeoTTF-dEb.eot") format("woff2"),
    url("/public/landing/fonts/eot/NanumSquareNeoTTF-dEb.eot")
      format("font-woff");
  font-weight: 600;
}

#landing-page {
   {
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -o-text-size-adjust: none;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: inherit;
    vertical-align: baseline;
    outline: none;
  }

  html,
  body {
    font-size: 62.5%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Noto_demi", sans-serif;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    color: #333;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section,
  summary {
    display: block;
  }

  ol,
  ul {
    list-style: none;
  }

  button {
    border: none;
    font-family: inherit;
  }

  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }

  strong {
    font-weight: bold;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  img {
    border: 0;
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    word-break: keep-all;
  }

  p {
    font-size: 16px;
    line-height: 26px;
    word-break: keep-all;
  }

  span {
    font-size: inherit;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
  }

  input {
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-border-radius: 0;
    font-family: inherit;
    border: 0;
    outline: none;
  }
  input[type="text"],
  input[type="password"],
  input[type="button"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
  input[type="submit"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  textarea {
    resize: none;
    font-family: inherit;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    outline: none;
  }

  select {
    border-radius: 0;
    font-family: inherit;
    outline: none;
  }
  select::-ms-expand {
    display: none;
  } /* IE 10, 11의 네이티브 화살표 숨기기 */
}
/*input 태그의 placeholder 색깔 변경하는 방법
input::-webkit-input-placeholder {}
input::-moz-placeholder {}
input::-ms-input-placeholder {} 
*/

/*드래그 하이라이트 색 변경
::selection { background: ; } 
::-moz-selection { background: ; } 
::-webkit-selection { background: ; }
*/

/* ### 크롬 스크롤 ### 
::-webkit-scrollbar {
    width:10px;
	height:4px;
}
::-webkit-scrollbar-track {
        -webkit-box-shadow:inset 0 0 3px rgba(0,0,0,0.3);
        border-radius:10px;
}
::-webkit-scrollbar-thumb {
        border-radius:10px;
        background:#fccc44;
        -webkit-box-shadow:inset 0 0 3px rgba(0,0,0,0.5);
}
*/
/* ### 익스 스크롤 ###
html {scrollbar-3dLight-Color: #efefef; scrollbar-arrow-color: #fccc44; scrollbar-base-color: #efefef; 
scrollbar-Face-Color: #fccc44; scrollbar-Track-Color: #efefef; scrollbar-DarkShadow-Color: #efefef; 
scrollbar-Highlight-Color: #efefef; scrollbar-Shadow-Color: #efefef}
 */
/* 
scrollbar-arrow-color는 스크롤 바 아래위 왼쪽 오른쪽 화살표 색이다. 
scrollbar-base-color는 스크롤 바 전체 배경 색이다. 
scrollbar-Face-Color는 상하좌우로 움직이는 부분(thumb), 화살표가 포함된 상하좌우 끝 사각형 부분(button) 색이다.
http://minineko.tistory.com/entry/CSS-scrollbar-IE-Chrome-Safari-%EC%8A%A4%ED%81%AC%EB%A1%A4%EB%B0%94-%EC%9D%B5%EC%8A%A4-%ED%81%AC%EB%A1%AC-%EC%82%AC%ED%8C%8C%EB%A6%AC
*/

/*로고 관련-검색최적화 관련해서 로고 파일에 회사명을 넣은뒤 text-indent를 주면 검색에는 적용되면서 웹에서는 보이지 않게 된다.
h1 { text-indent: -9999px; margin: 0 auto; width: 320px; height: 85px; background: transparent url("images/logo.png") no-repeat scroll; }
*/

/*---------------------------css 커스터마이징-------------------------------------------*/

#landing-page {
  .container {
    margin: 0 auto;
    width: 1200px;
  }
  .container_fluid {
    margin: 0 auto;
  }
  .text_left {
    text-align: left;
  }
  .text_right {
    text-align: right;
  }
  .text_center {
    text-align: center;
  }
  .invisible {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
  .hide {
    display: none;
  }
  .show {
    display: block !important;
  }
  .float_l {
    float: left;
  }
  .float_r {
    float: right;
  }
  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .po_fixed {
    position: fixed;
  }
  .po_abs {
    position: absolute;
  }
  .po_rel {
    position: relative;
  }
  .po_static {
    position: static;
  }
  .clear {
    clear: both;
  }
  .over_h {
    overflow: hidden;
  }
  .z_10 {
    z-index: 10;
  }
  .z_100 {
    z-index: 100;
  }
  .z_1000 {
    z-index: 1000;
  }
  .z_9999 {
    z-index: 9999;
  }
  .img_center {
    display: block;
    margin: 0 auto;
  }
  .img_center_1920 {
    left: 50%;
    margin-left: -960px;
    position: relative;
  }
  .ul_center {
    display: table;
    margin: 0 auto;
  }

  .mar_t_0 {
    margin-top: 0 !important;
  }
  .mar_l_0 {
    margin-left: 0 !important;
  }
  .mar_r_0 {
    margin-right: 0 !important;
  }
  .mar_b_0 {
    margin-bottom: 0 !important;
  }
  .pad_t_0 {
    padding-top: 0 !important;
  }
  .pad_l_0 {
    padding-left: 0 !important;
  }
  .pad_r_0 {
    padding-right: 0 !important;
  }
  .pad_b_0 {
    padding-bottom: 0 !important;
  }

  .bor_t_0 {
    border-top: 0 !important;
  }
  .bor_l_0 {
    border-left: 0 !important;
  }
  .bor_r_0 {
    border-right: 0 !important;
  }
  .bor_b_0 {
    border-bottom: 0 !important;
  }
  .bor_0 {
    border: none !important;
  }

  .mar_b_50 {
    margin-bottom: 50px;
  }
  .mar_b_80 {
    margin-bottom: 80px;
  }
  .mar_b_100 {
    margin-bottom: 100px;
  }
  .mar_b_120 {
    margin-bottom: 120px;
  }
  .mar_b_150 {
    margin-bottom: 150px;
  }

  .mar_t_50 {
    margin-top: 50px;
  }
  .mar_t_60 {
    margin-top: 60px;
  }
  .mar_t_80 {
    margin-top: 80px;
  }
  .mar_t_100 {
    margin-top: 100px;
  }
  .mar_t_150 {
    margin-top: 150px;
  }

  .pad_t_50 {
    padding-top: 50px;
  }
  .pad_t_100 {
    padding-top: 100px;
  }
  .pad_t_120 {
    padding-top: 120px;
  }
  .pad_t_150 {
    padding-top: 150px;
  }

  .pad_b_50 {
    padding-bottom: 50px;
  }
  .pad_b_100 {
    padding-bottom: 100px;
  }
  .pad_b_120 {
    padding-bottom: 120px;
  }
  .pad_b_150 {
    padding-bottom: 150px;
  }

  .pad_lr_80 {
    padding: 0 80px;
  }

  .tri_down {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-top: 9px solid #ffc208;
    border-bottom: 9px solid transparent;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
  }
  .tri_up {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-top: 9px solid transparent;
    border-bottom: 9px solid #ffc208;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
  }
  .tri_right {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid transparent;
    border-left: 9px solid #ffc208;
  }
  .tri_left {
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid #ffc208;
    border-left: 9px solid transparent;
  }
  .after:after {
    content: "";
    clear: both;
    display: block;
  }
  .font_b {
    font-weight: bold;
  }
  .vh_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
  }
  .text_hide {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  .video {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.26%;
  }
  .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /*--------------------------- 그누보드 관리자 -------------------------------------------*/
  a.btn,
  .btn {
    font-weight: normal;
  }

  #container {
    margin-top: 0;
  }
  #wrapper {
    font-size: 14px;
  }
  #wrapper h2 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  #mb_login {
    font-size: 14px;
  }
  #hd {
    font-size: 14px;
  }
  .sv_member .profile_img {
    vertical-align: middle;
  }
  #ft p {
    font-size: 12px;
    letter-spacing: 0;
  }
  .container_wr {
    margin-top: 100px;
  }

  .tbl_head01 thead th,
  .tbl_head01 tbody td,
  .tbl_frm01 th {
    font-size: 14px;
    vertical-align: middle;
  }
  .btn_list03 a {
    line-height: 28px;
  }
  #bo_list_total {
    font-size: 14px;
  }
  .btn_bo_user {
    font-size: 12px;
  }

  .new_win h1 {
    font-size: 16px;
  }
  .tbl_frm01 td {
    font-size: 14px;
  }
  .new_win .win_btn {
    font-size: 14px;
  }

  #bo_w .write_div {
    font-size: 14px;
  }
  #bo_v_title .bo_v_tit {
    font-size: 24px;
    line-height: 40px;
  }
  #bo_v_info {
    font-size: 14px;
  }
  .cmt_btn span.total {
    font-size: 12px;
  }
  .bo_vc_w {
    font-size: 14px;
    line-height: 20px;
  }

  /* list */
  #bo_gall li.empty_list {
    font-size: 16px;
  }
  .chk_box input[type="checkbox"] + label {
    font-size: 14px;
  }
  #bo_gall .bo_tit {
    font-size: 16px;
  }
  #bo_gall .gall_info {
    font-size: 14px;
  }
  #bo_gall .bo_cnt {
    font-size: 14px;
  }
  .bo_sch .bo_sch_cls {
    background: none;
  }

  /* view */
  #bo_v_share {
    display: none;
  }
  #bo_v_con {
    margin: 30px 0;
    font-size: 16px;
    line-height: 30px;
  }
  #bo_v_info .profile_info .pf_img {
    display: none;
  }
  #bo_v_info .profile_info .profile_info_ct {
    line-height: 25px;
  }
  .bo_v_nb li {
    font-size: 14px;
  }
  #bo_vc article .pf_img {
    display: none;
  }
  #bo_vc header {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .more_opt li button,
  .more_opt li a {
    font-size: 14px;
    line-height: 20px;
  }

  .bo_sch {
    font-size: 14px;
  }

  .back_page {
    display: block;
    width: 200px;
    line-height: 50px;
    border: 1px solid #eee;
    font-size: 16px;
    text-align: center;
    margin: 50px auto;
  }
  .back_page a {
    display: block;
  }
}
