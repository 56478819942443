/**** 공통 ****/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@keyframes ani {
  0% {
    top: 48%;
  }
  100% {
    top: 52%;
  }
}

#landing-page {
  .poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  .poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .container_wrap {
    max-width: 1350px;
    padding: 0 15px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
  }

  .scroll-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 999;
    background: var(--color-main-blue);
    width: 60px;
    height: 60px;
    border-radius: 50px;
    transition: all 0.4s;
  }

  .scroll-top i {
    font-size: 30px;
    color: #fff;
    line-height: 0;
  }

  .scroll-top:hover {
    background: var(--color-main-blue-hover);
    color: #fff;
  }

  .scroll-top.active {
    visibility: visible;
    opacity: 1;
  }

  html,
  body {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  /**** header ****/

  /**** footer ****/

  /**** 메인 페이지 ****/
  .main_banner {
    width: 100%;
    height: 700px;
    border-radius: 70px 70px 0 0;
    background: url("/public/landing/images/main_img_blue_bg.jpg") no-repeat
      center;
    background-size: cover;
  }
  .main_banner .container_wrap {
    height: 100%;
  }
  .main_banner .text_box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  .main_banner .mobile_text_box {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
  }
  .main_banner .text_box h2 {
    font-size: 50px;
    line-height: 60px;
    color: #4b84da;
    font-weight: 700;
  }
  .main_banner .mobile_text_box h2 {
    font-size: 36px;
    line-height: 60px;
    color: #4b84da;
    font-weight: 700;
  }
  .main_banner .text_box p {
    font-size: 20px;
    line-height: 30px;
    color: #5e646d;
    margin-top: 20px;
  }
  .main_banner .mobile_text_box p {
    font-size: 20px;
    line-height: 30px;
    color: #5e646d;
    margin-top: 20px;
  }

  .main_banner .img {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    right: 10%;
    max-width: 30%;
    animation: ani 1.2s infinite alternate;
  }
  .main_banner .mobile_img {
    position: absolute;
    right: 12%;
    animation: ani 1.2s infinite alternate;
    text-align: center;
  }

  .part_title_wrap {
    margin-bottom: 80px;
    text-align: center;
  }
  .part_title_wrap .part_title {
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
  }
  .part_title_wrap p {
    font-size: 20px;
    line-height: 30px;
    color: #555;
    margin-top: 30px;
  }
  .service_part {
    background: #f9fbff;
  }
  .service_part ul li {
    background: #fff;
    border-radius: 50px;
    border: 1px solid #f1f2fb;
    margin-bottom: 30px;
    padding: 50px;
  }
  .service_part ul li:last-child {
    margin-bottom: 0;
  }
  .service_part ul li img {
    width: 200px;
  }
  .service_part ul li .text_box {
    width: calc(100% - 200px);
    padding-left: 50px;
    padding-top: 30px;
  }
  .service_part ul li .text_box h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .service_part ul li .text_box p {
    font-size: 18px;
    line-height: 30px;
    color: #555;
  }

  .count_part {
    background: url("/public/landing/images/bg_2.jpg") no-repeat center;
    background-size: cover;
  }
  .count_part ul {
    text-align: center;
  }
  .count_part ul li {
    display: inline-block;
    width: 25%;
    margin: 0 2.5%;
    background: white;
    border-radius: 50px;
    box-shadow: 0 8px 30px rgba(75, 132, 218, 0.2);
    text-align: center;
    padding: 60px 0;
  }
  .count_part ul li .count {
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
    color: #4b84da;
    margin-bottom: 30px;
    display: block;
  }
  .count_part ul li p {
    color: #999;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }

  .features_part .symbol {
    height: 100px;
    margin-bottom: 50px;
  }
  .features_part ul li {
    border-bottom: 1px solid #f5f5f5;
    padding: 50px 0;
  }
  .features_part ul li:first-child {
    padding-top: 0;
  }
  .features_part ul li:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
  .features_part ul li .img {
    width: 120px;
  }
  .features_part ul li .text_box {
    width: calc(100% - 120px);
    padding-left: 50px;
  }
  .features_part ul li .text_box span {
    display: block;
    color: #4b84da;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
  }
  .features_part ul li .text_box h4 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .features_part ul li .text_box p {
    font-size: 18px;
    line-height: 28px;
    color: #555;
  }
  .features_part ul li .text_box .card_list {
    margin-top: 20px;
  }
  .features_part ul li .text_box .card_list img {
    display: inline-block;
    margin-right: 20px;
  }

  .membership_part {
    background: #f9fbff;
  }
  .membership_part .grade .list {
    float: left;
    width: 30%;
    margin: 0 1.5%;
    border-radius: 50px;
    padding: 50px;
    text-align: center;
  }
  .membership_part .grade .list:first-child {
    margin-left: 0;
  }
  .membership_part .grade .list:last-child {
    margin-right: 0;
  }
  .membership_part .grade .list.green {
    background: #52bd70;
  }
  .membership_part .grade .list.blue {
    background: #526abd;
    position: relative;
  }
  .membership_part .grade .list.red {
    background: #cf4444;
  }
  .membership_part .grade .list h4 {
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    color: white;
  }
  .membership_part .grade .list h5 {
    font-size: 18px;
    line-height: 28px;
    color: white;
    margin-top: 10px;
  }
  .membership_part .grade .list ul {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px 0;
    margin-top: 30px;
  }
  .membership_part .grade .list ul li {
    font-size: 16px;
    line-height: 24px;
    color: white;
    text-align: left;
  }
  .membership_part .grade .list ul li:first-child {
    margin-bottom: 15px;
  }
  .membership_part .grade .list a {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    background: white;
    border-radius: 30px;
    margin-top: 40px;
  }
  .membership_part .grade .list.green a {
    color: #52bd70;
  }
  .membership_part .grade .list.blue a {
    color: #526abd;
  }
  .membership_part .grade .list.red a {
    color: #cf4444;
  }
  .membership_part .grade .list.blue img {
    position: absolute;
    top: -7px;
    right: -6px;
  }

  .review_part .swiper-container {
    padding: 30px 60px;
  }
  .review_part .list {
    width: calc(33% - 30px);
    text-align: center;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
  }
  .review_part .list a {
    display: block;
    padding: 50px 0;
    border-radius: 25px;
  }
  .review_part .list h4 {
    margin-top: 30px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
  }
  .review_part .list .star {
    margin-top: 15px;
    padding-left: 0;
  }
  .review_part .list .star li {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("/public/landing/images/star_off.png") no-repeat center;
    background-size: cover;
  }
  .review_part .list .star li.on {
    background: url("/public/landing/images/star_on.png") no-repeat center;
    background-size: cover;
  }
  .review_part .list .thumb {
    width: 100%;
    height: 250px;
    margin-top: 30px;
  }
  .review_part .list .thumb.no-img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    width: 100%;
    height: 250px;
    font-weight: bold;
  }
  .review_part .list .thumb img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .review_part .list .content {
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
    color: #555;
    padding: 0 30px;
    margin-bottom: 30px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .shop_part {
    background: #f9f9f9;
  }
  .shop_part .tabs {
    text-align: center;
  }
  .shop_part .tabs li {
    display: inline-block;
    font-size: 20px;
    line-height: 20px;
    color: #999;
    margin: 0 20px;
    font-weight: 600;
    cursor: pointer;
  }
  .shop_part .tabs li.active {
    color: #4b84da;
  }
  .shop_part .nice-select {
    display: none;
  }
  .shop_part .tab_content {
    transition: none;
  }
  .shop_part .tab_content ul {
    text-align: center;
    margin-top: 30px;
  }
  .shop_part .tab_content ul li {
    display: inline-block;
    margin: 15px 10px;
    padding: 20px 30px;
    background: white;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  }
  .shop_part .tab_content ul li img {
    height: 30px;
  }

  .insta_part {
    background: url("/public/landing/images/insta_bg.jpg") no-repeat top center;
    background-size: cover;
  }
  .insta_part ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .insta_part ul li {
    width: 25%;
    padding: 10px;
    box-sizing: border-box;
  }
  .insta_part ul li a {
    display: block;
    border-radius: 20px;
    overflow: hidden;
  }
  .insta_part ul li a img {
    width: 100%;
    height: auto;
  }

  .blog_part ul {
  }
  .blog_part ul li {
    float: left;
    width: 30%;
    margin: 0 1.5%;
    border-radius: 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }
  .blog_part ul li a {
    display: block;
    border-radius: 25px;
  }
  .blog_part ul li a .thumb {
    width: 100%;
    height: 245px;
  }
  .blog_part ul li a .thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .blog_part ul li a .content {
    padding: 30px;
  }
  .blog_part ul li a .content h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .blog_part ul li a .content p {
    font-size: 16px;
    line-height: 24px;
    color: #555;
  }
  .blog_part ul li a .content .info {
    margin-top: 20px;
  }
  .blog_part ul li a .content .info .date {
    font-size: 14px;
    line-height: 14px;
    color: #999;
  }
  .blog_part ul li a .content .info .like {
    display: flex;
    align-items: center;
  }
  .blog_part ul li a .content .info .like img {
    width: 14px;
    margin-right: 10px;
  }
  .blog_part ul li a .content .info .like span {
    font-size: 14px;
    line-height: 14px;
    color: #4b84da;
    font-weight: 600;
  }

  .join_part {
    background: url("/public/landing/images/coupon_bg_3.png") no-repeat bottom
      center #f9fbff;
    background-size: 65%;
  }
  .join_part .content {
    text-align: center;
  }
  .join_part .content h3 {
    font-size: 35px;
    line-height: 45px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .join_part .content p {
    font-size: 24px;
    line-height: 40px;
    color: #555;
  }
  .join_part .content .join_btn {
    display: inline-block;
    margin-top: 60px;
    width: 320px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    background: #4b84da;
    color: white;
    font-weight: 600;
    font-size: 18px;
  }

  /**** 푸터 ****/
  footer {
    background: #2a2e35;
    padding: 80px 0;
    letter-spacing: 0;
  }
  footer .part {
    float: left;
    width: 50%;
    padding-right: 25px;
  }
  footer .part h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 15px;
    font-weight: 600;
    color: white;
  }
  footer .part p {
    font-size: 14px;
    line-height: 20px;
    color: white;
  }

  /**** service ****/
  .video_part .text_box {
    width: 55%;
  }
  .video_part .mobile_text_box {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .video_part .text_box img,
  .video_part .mobile_text_box img {
    height: 260px;
    margin-bottom: 50px;
  }

  .video_part .text_box h2,
  .video_part .mobile_text_box h2 {
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
  }

  .video_part .text_box p,
  .video_part .mobile_text_box p {
    font-size: 18px;
    line-height: 30px;
    color: #555;
  }

  .video_wrap {
    position: absolute;
    top: 0;
    width: 45%;
  }
  .video {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 56.26%;
  }
  .video .youtube-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .link_btn {
    display: block;
    margin: 60px auto 0;
    width: 320px;
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    background: #4b84da;
    color: white;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    letter-spacing: 0;
  }

  .service_part_1 .video_wrap,
  .service_part_3 .video_wrap {
    right: 0;
  }
  .service_part_2 .video_wrap {
    left: 0;
  }
  .service_part_1 .text_box,
  .service_part_3 .text_box {
    padding-right: 100px;
  }
  .service_part_3 .mobile_text_box {
    padding-right: 0;
  }
  .service_part_1 .mobile_text_box,
  .service_part_3 .mobile_text_box {
    padding-right: 0;
  }
  .service_part_2 .text_box {
    padding-left: 100px;
  }
  .service_part_2 .mobile_text_box {
    padding-left: 0;
  }

  .service_part_1 {
    background: #fefbf7;
  }
  .service_part_1 ul {
    background: #fff;
    padding: 30px 50px;
    border-radius: 50px;
  }
  .service_part_1 ul li {
    border-bottom: 1px solid #f5f5f5;
    padding: 50px 30px;
  }
  .service_part_1 ul li:first-child {
  }
  .service_part_1 ul li:last-child {
    border-bottom: 0;
  }
  .service_part_1 ul li img {
    width: 150px;
  }
  .service_part_1 ul li .text_box {
    width: calc(100% - 150px);
    padding-left: 50px;
  }
  .service_part_1 ul li .text_box h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .service_part_1 ul li .text_box p {
    font-size: 18px;
    line-height: 30px;
    color: #555;
  }

  .service_part_2 {
    background: #f9fbff;
  }
  .service_part_2 ul li {
    float: left;
    width: 46%;
    margin: 2%;
    background: white;
    border-radius: 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    padding: 50px;
    min-height: 380px;
  }
  .service_part_2 ul li h3 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    color: #4b84da;
    text-align: center;
  }
  .service_part_2 ul li span {
    display: block;
    margin: 30px auto;
    width: 100px;
    height: 1px;
    background: #ccc;
  }
  .service_part_2 ul li p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 10px;
    letter-spacing: 0;
  }

  .service_part_3 ul li {
    border-bottom: 1px solid #f5f5f5;
    padding: 50px 0;
  }
  .service_part_3 ul li:first-child {
    border-top: 1px solid #f5f5f5;
  }
  .service_part_3 ul li:last-child {
  }
  .service_part_3 ul li .img {
    width: 120px;
  }
  .service_part_3 ul li .text_box {
    width: calc(100% - 120px);
    padding-left: 50px;
  }
  .service_part_3 ul li .text_box span {
    display: block;
    color: #4b84da;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px;
  }
  .service_part_3 ul li .text_box h4 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .service_part_3 ul li .text_box p {
    font-size: 18px;
    line-height: 28px;
    color: #555;
  }
  .service_part_3 ul li .text_box .more,
  .service_part_3 ul li .mobile_text_box .more {
    background: #4b84da;
    display: inline-block;
    padding: 13px 20px;
    color: white;
    border-radius: 10px;
    margin-top: 15px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0;
  }

  /**** pricing ****/
  .pricing_page_title {
    position: relative;
    margin-top: 100px;
    min-width: 1320px;
    width: calc(100% - 200px);
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 80px;
    background: url("/public/landing/images/pricing_bg.jpg") no-repeat center
      right;
    background-size: cover;
  }
  .pricing_page_mobile_title {
    position: relative;
    margin-top: 80px;
    min-width: 100%;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px;
    background: url("/public/landing/images/pricing_bg.jpg") no-repeat center
      right;
    background-size: cover;
  }
  .pricing_page_title .container_wrap,
  .pricing_page_mobile_title .container_wrap {
    height: 100%;
  }
  .pricing_page_title img {
    position: absolute;
    right: 0;
    bottom: -20%;
  }
  .pricing_page_mobile_title img {
    position: absolute;
    right: 0;
    bottom: -20%;
    width: 50%;
  }
  .pricing_page_title h2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
  }
  .pricing_page_mobile_title h2 {
    position: absolute;
    top: 30%;
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
    left: 8%;
  }
  .price_info_wrap .nice-select {
    display: none;
  }
  .price_info_wrap .tabs {
    float: left;
    width: 250px;
    background: #fff;
    border-radius: 25px;
    padding: 10px 30px;
  }
  .price_info_wrap .mobile_tabs {
    float: left;
    width: 100%;
    background: #fff;
    border-radius: 25px;
    padding: 10px 30px;
  }
  .price_info_wrap .tabs li,
  .price_info_wrap .mobile_tabs li {
    font-size: 18px;
    line-height: 18px;
    color: #999;
    margin: 20px 0;
    cursor: pointer;
    transition: none;
  }
  .price_info_wrap .tabs li.active,
  .price_info_wrap .mobile_tabs li.active {
    color: #4b84da;
    font-weight: 600;
  }
  .price_info_wrap .tab_container {
    float: left;
    width: calc(100% - 280px);
    margin-left: 30px;
    background: #fff;
    border-radius: 25px;
    padding: 50px;
  }
  .price_info_wrap .mobile_tab_container {
    float: left;
    /* width: calc(100% - 280px); */
    width: 100%;
    padding: 16px 0;
    /* margin-left: 30px; */
    background: #fff;
    border-radius: 25px;
    /* padding: 50px; */
  }
  .price_info_wrap .tab_content {
    min-height: 600px;
  }
  .price_info_wrap .tab_content .title {
    text-align: center;
    margin-bottom: 50px;
  }
  .price_info_wrap .tab_content .title h3 {
    display: inline-block;
    font-size: 24px;
    line-height: 24px;
    padding: 20px 0;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    font-weight: 600;
  }
  .price_info_wrap .tab_content .table_title {
    font-size: 18px;
    line-height: 18px;
    margin-top: 50px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .price_info_wrap .tab_content .info_wrap {
    margin-top: 10px;
  }
  .price_info_wrap .tab_content .info_wrap .info {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    color: #999;
  }
  .price_info_wrap .tab_content .table_wrap {
    overflow: auto;
  }
  .price_info_wrap .tab_content .table_wrap table {
    width: 100%;
    min-width: 500px;
  }
  .price_info_wrap .tab_content .table_wrap table tr {
    border-bottom: 1px solid #eee;
  }
  .price_info_wrap .tab_content .table_wrap table th {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    vertical-align: middle;
    height: 60px;
    text-align: center;
  }
  .price_info_wrap .tab_content .table_wrap table td {
    font-size: 16px;
    line-height: 24px;
    padding: 30px 10px;
    vertical-align: middle;
    text-align: center;
  }
  .price_info_wrap .tab_content .table_wrap table td a {
    color: #2e93f0;
    text-decoration: underline;
  }
  .price_info_wrap .tab_content .table_wrap table td.list {
    font-weight: 600;
  }
  .price_info_wrap .tab_content .table_wrap table th.th_20 {
    width: 20%;
  }
  .price_info_wrap .tab_content .table_wrap table th.th_25 {
    width: 25%;
  }
  .price_info_wrap .tab_content .table_wrap table th.th_30 {
    width: 30%;
  }
  .price_info_wrap .tab_content .table_wrap table th.th_37 {
    width: 37%;
  }
  .price_info_wrap .tab_content .table_wrap table th.th_50 {
    width: 50%;
  }
}
