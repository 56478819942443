/**
 CONTETNS
*/

.cl-content {
  position: fixed;
  top: 55px;
  height: calc(100vh - 55px);
  max-height: 100%;
  transition-duration: 0.3s;
}

.cl-content-wide {
  width: 100%;
  left: 0px;

  @media (max-width: 1279px) {
    left: 0px;
  }

  @media (max-width: 767px) {
    left: 0px;
  }

  @media (max-width: 575px) {
    left: 0px;
  }
}

.cl-content-narrow {
  width: calc(100vw - 300px);
  left: 300px;

  @media (max-width: 1279px) {
    left: 0px;
    width: 100vw;
  }

  @media (max-width: 767px) {
    left: 0px;
    width: 100vw;
  }

  @media (max-width: 575px) {
    left: 0px;
    width: 100vw;
  }
}

.cl-content #cl-content-inner {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/**
 SIDEBAR
*/
.cl-sidebar {
  position: fixed;
  top: 55px;
  left: 0;
  height: calc(100vh - 55px);
  max-height: 100%;
  overflow-y: scroll;
  width: 300px;
  background-color: var(--color-sidebar);
  color: var(--color-white);
  z-index: 996;
  transition-duration: 0.3s;
}

.cl-sidebar-mobile {
  position: fixed;
  top: 55px;
  left: 0;
  height: calc(100vh - 55px);
  max-height: 100%;
  overflow-y: scroll;
  width: 15rem;
  background-color: var(--color-sidebar);
  color: var(--color-white);
  z-index: 996;
  transition-duration: 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cl-sidebar-narrow {
  left: -300px;
}

.cl-sidebar .cl-sidebar-inner {
  width: 100%;
  padding-bottom: 120px;
}

.cl-sidebar .cl-sidebar-ul {
  margin-left: 0px;
  padding-left: 0px;
}

.cl-sidebar .cl-sidebar-ul li div:hover {
  background-color: var(--color-sidebar-hover);
}

.cl-sidebar .cl-sidebar-menu {
  margin: 10px 4px 4px 4px;
  padding: 0.5rem 1rem;
  transition-duration: 0.3s;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.cl-sidebar .cl-sidebar-menu:hover {
  color: white;
}
.cl-sidebar .selected {
  color: white;
}

.cl-sidebar-submenu {
  padding: 0px;
  /* padding-left: 25px; */
  cursor: pointer;
}

.cl-sidebar-submenu li {
  padding: 10px 10px 10px 25px;
  transition-duration: 0.3s;
  cursor: pointer;
}
.cl-sidebar-submenu li:hover {
  color: white;
  background-color: var(--color-sidebar-hover);
}
.cl-sidebar-submenu li.active {
  color: white;
  background-color: var(--color-sidebar-hover);
}

/** 
HEADER
*/

.cl-header {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 55px;
  box-shadow: 0px 4px 10px #00000012;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* overflow: hidden; */
  white-space: nowrap;
}
.cl-header-ci {
  color: var(--color-black);
  font-size: 24px;
  font-weight: bold;
  text-overflow: ellipsis;
  word-break: break-all;
}

.cl-header-customer {
  z-index: 999;
  display: flex;
  gap: 5px;
  align-items: center;
  padding-right: 10px;
  position: relative;
}

.cl-header-alarm {
  z-index: 999;
  button::after {
    display: none;
  }
}
